<template>
  <v-dialog
    v-model="isShow"
    class="dialog-modal"
    @click:outside="$emit('close')"
    max-width="500px"
  >
    <button @click="closeButton(step)" class="modal-close-btn">
      <img src="@/assets/images/modal/close-circle.png" width="22px" />
    </button>
    <div class="verification-modal" v-if="step === 0">
      <img
        :src="require('@/assets/images/modal/security.png')"
        class="modal-image"
        width="50px"
      />
      <div class="modal-title">
        {{ $t("Validation.secVerification") }}
      </div>
      <div class="modal-description">
        {{ $t("Validation.secVerificationDesc") }}
      </div>
      <div class="modal-description">
        {{ $t("Validation.secVerificationDesc2") }}
      </div>

      <!-- Passkey. -->
      <button
        class="verification-btn"
        :class="!validationStatus.passkey_required ? 'selected' : ''"
        @click="step = 1"
        v-if="isPasskeyEnabled"
      >
        {{ $t("Validation.passkeys") }}
        <img
          src="@/assets/images/wallet/round_check_icon.svg"
          width="24"
          v-if="!validationStatus.passkey_required"
        />
        <v-icon v-else>mdi-chevron-right</v-icon>
      </button>
      <div
        class="verification-btn fake-btn"
        v-else-if="$route.name !== `addPasskeys`"
      >
        {{ $t("Validation.passkeys") }}

        <button
          type="button"
          class="enable-btn"
          @click="navigateToRoute('addPasskeys')"
        >
          {{ $t(`Validation.enableNow`) }}
        </button>
      </div>

      <!-- Auth App. -->
      <button
        class="verification-btn"
        :class="!validationStatus.totp_required ? 'selected' : ''"
        @click="step = 2"
        v-if="isTotpEnabled"
      >
        {{ $t("Validation.authApp") }}
        <img
          src="@/assets/images/wallet/round_check_icon.svg"
          width="24"
          v-if="!validationStatus.totp_required"
        />
        <v-icon v-else>mdi-chevron-right</v-icon>
      </button>

      <!-- Email. -->
      <button
        class="verification-btn"
        :class="!validationStatus.otp_required ? 'selected' : ''"
        @click="
          step = 3;
          getEmailCode();
        "
      >
        {{ $t("Validation.emailVerification") }}
        <img
          src="@/assets/images/wallet/round_check_icon.svg"
          width="24"
          v-if="!validationStatus.otp_required"
        />
        <v-icon v-else>mdi-chevron-right</v-icon>
      </button>
    </div>

    <div class="modal-body-custom" v-else-if="step === 1">
      <!-- Passkey. -->
      <img src="@/assets/images/auth/passkeys.png" class="modal-image" />

      <div class="modal-title">
        {{ $t("Validation.verifyPasskey") }}
      </div>
      <div class="modal-description w-90 w-space-break">
        {{ $t("Validation.verifyingPasskey") }}
      </div>
      <div class="loading">
        <span class="spinner-border-lg spinner-border"></span>
      </div>
    </div>

    <div class="modal-body-custom" v-else-if="step === 2">
      <!-- 2FA. -->
      <img src="@/assets/images/auth/2FA-icon.png" class="modal-image" />

      <div class="modal-title">
        {{ $t("Validation.authAppVerification") }}
      </div>
      <div class="modal-description w-90 w-space-break">
        {{ $t("Validation.enter2FACodeDesc2") }}
      </div>

      <PincodeInput
        v-model="code"
        :errorMessage="codeError"
        :on-check="validateTotp()"
        @clearError="codeError = ''"
        :disabled="isLoading"
      />

      <button
        class="secondary-btn"
        @click="
          step = 4;
          isOtp = false;
        "
        :disabled="isLoading"
      >
        {{ $t("Login.trouble") }}
      </button>
    </div>

    <div class="modal-body-custom" v-else-if="step === 3">
      <!-- Email OTP. -->
      <img src="@/assets/images/auth/email-icon.png" class="modal-image" />

      <div class="modal-title">
        {{ $t("Validation.emailVerification") }}
      </div>

      <div
        class="modal-description w-space-break"
        v-html="
          $t('Validation.enter6digit', {
            email: isBusiness ? secureEmail : email,
          })
        "
      ></div>

      <PincodeInput
        v-model="emailCode"
        :on-check="validateOtp()"
        :errorMessage="emailCodeError"
        @clearError="emailCodeError = ''"
        :disabled="isLoading"
      />
      <button
        class="main-btn"
        @click="getEmailCode"
        :disabled="emailDisabled || isSubmitLoading"
      >
        {{ $t("Profile.resendViaEmail") }}
        <span v-if="emailDisabled">
          ({{ emailTimerCount }}{{ $t("Verification.second") }})
        </span>
      </button>
      <button
        class="secondary-btn"
        @click="
          step = 4;
          isOtp = true;
        "
        :disabled="isLoading"
      >
        {{ $t("Login.trouble") }}
      </button>
    </div>

    <!-- HAVING TROUBLE -->
    <div class="modal-body-custom" v-else-if="step === 4">
      <div class="modal-title">{{ $t("TwoFA.havingTrouble") }}</div>

      <span
        v-for="(item, index) in isOtp ? otpList : totpList"
        :key="index"
        class="w-100"
      >
        <a
          class="having-trouble-item"
          :href="item.link"
          target="_blank"
          v-if="item.isNewTab"
        >
          <img
            :src="require(`@/assets/images/${item.img}`)"
            width="40px"
            v-if="item.img"
          />
          <div>{{ item.title }}</div>
        </a>

        <button
          class="having-trouble-item"
          @click="navigateToRoute(item.link)"
          v-else
        >
          <img
            :src="require(`@/assets/images/${item.img}`)"
            width="40px"
            v-if="item.img"
          />
          <div>{{ item.title }}</div>
        </button>
      </span>
    </div>

    <div class="modal-body-custom" v-else-if="step === 5">
      <!-- Failed Passkey. -->
      <img src="@/assets/images/auth/error.png" class="modal-image" />

      <div class="modal-title fail-text">
        {{ $t("Validation.passkeyError") }}
      </div>
      <div class="modal-description">
        {{ $t("Validation.unableToVerify") }}
      </div>
    </div>
  </v-dialog>
</template>

<script>
// For these methods, need to check whether they are enabled, else ignore them during validation.
// Match the enabled method name in `data`.
const validationStatusToDataKey = {
  totp_required: `isTotpEnabled`,
  passkey_required: `isPasskeyEnabled`,
};

export default {
  props: {
    dialog: { type: Boolean, default: false },
    validationData: { type: Object },
    sendInfo: { type: Object },
  },

  data() {
    return {
      step: 0,
      code: "",
      codeError: "",
      emailCode: "",
      emailCodeError: "",
      emailDisabled: false,
      isSubmitLoading: false,
      email: this.$store.state.user.email,
      emailTimerCount: 0,
      methods: [
        {
          title: this.$t("Validation.authApp"),
          value: 2,
        },
        {
          title: this.$t("Validation.emailVerification"),
          value: 3,
        },
      ],
      isLoading: false,
      validationStatus: {
        passkey_required: true,
        totp_required: true,
        otp_required: true,
      },
      havingTrouble: false,
      totpList: [
        {
          img: "auth/lock-icon.png",
          title: this.$t("TwoFA.unableAccess"),
          link: "https://support.hata.io/support/tickets/new",
          isNewTab: true,
        },
        {
          img: "auth/2FA-not-working.png",
          title: this.$t("TwoFA.codeNotWorking"),
          link: "https://support.hata.io/support/solutions/articles/150000107432-how-to-solve-2fa-code-error",
          isNewTab: true,
        },
      ],
      otpList: [
        {
          img: "profile/gradient-email.png",
          title: this.$t("Validation.noReceiveOTP"),
          link: "https://support.hata.io/support/tickets/new",
          isNewTab: true,
        },
        {
          img: "profile/gradient-password.png",
          title: this.$t("TwoFA.codeNotWorking"),
          link: "https://support.hata.io/support/solutions/articles/150000107672-why-can-t-i-receive-emails-from-hata",
          isNewTab: true,
        },
      ],
      isOtp: true,

      // Enabled methods.
      isTotpEnabled: !!(
        this.validationData.totp_enabled && this.$store.state.user.twofa_enabled
      ),
      isPasskeyEnabled: !!(
        this.validationData.passkey_enabled &&
        this.$store.state.user.passkey_enabled
      ),

      // For passkey.
      attestationResponse: {},

      // Track validation API calling.
      isValidating: false,
    };
  },

  watch: {
    step: {
      handler() {
        if (this.step === 0) {
          !this.isValidating && this.requestValidation();
        } else if (this.step === 1) {
          if (!this.isPasskeyEnabled) {
            this.step = 0; // Reset step.
            return;
          }
          this.beginValidatePasskey();
        } else if (this.step === 2 && !this.isTotpEnabled) {
          this.step = 0; // Reset step.
        } else if (this.step === 5 && !this.isPasskeyEnabled) {
          this.step = 0; // Reset step.
        }
      },
    },

    isShow: {
      handler() {
        if (this.isShow) {
          !this.isValidating && this.requestValidation();
          this.step = 0; // Reset step.
        }
      },
    },

    validationData: {
      handler(newValidationData) {
        // console.log(`validationData Type`, typeof newValidationData);
        // if (typeof newValidationData !== 'object') {
        //   return;
        // }

        this.isTotpEnabled =
          newValidationData.totp_enabled &&
          this.$store.state.user.twofa_enabled;
        this.isPasskeyEnabled =
          newValidationData.passkey_enabled &&
          this.$store.state.user.passkey_enabled;

        this.validationStatus = {
          passkey_required: this.isPasskeyEnabled,
          totp_required: this.isTotpEnabled,
          otp_required: true,
        };
      },
    },
  },

  computed: {
    isShow() {
      return this.dialog;
    },

    isBusiness() {
      return this.$store.state.user.business;
    },

    secureEmail() {
      return this.$store.state.user.secure_email;
    },
  },

  // created() {
  //   window.addEventListener('beforeunload', this.cleanup);
  // },

  // beforeUnmount() {
  //   window.removeEventListener('beforeunload', this.cleanup);
  // },

  methods: {
    // cleanup() {
    //   sessionStorage.removeItem('passkey_session');
    // },

    closeButton(value) {
      switch (value) {
        case 0:
          this.$emit("close");
          break;
        default:
          this.step = 0;
      }
    },

    async getEmailCode() {
      if (!this.emailDisabled) {
        this.isFail = false;
        this.emailDisabled = true;

        let params = {
          email: this.$store.state.user.email,
          amount: this.sendInfo.amount,
          symbol: this.sendInfo.coin,
          toAddress: this.sendInfo.toAddress,
          accountNumber: this.sendInfo.accountNumber,
          bankName: this.sendInfo.bankName,
          action: this.sendInfo.action,
          platform: this.$store.state.preferences.platform == "1" ? "MY" : "WW",
          request_id: this.sendInfo.request_id,
        };

        await this.$store
          .dispatch("user/requestEmailOtp", { params })
          .then(() => {
            this.countDownEmailTimer();
          })
          .catch((error) => {
            this.isFail = true;
            this.failTitle = this.$t("Modal.failToObtainEmailCode");
            this.errorMessage = this.errorCheck(error);
            this.emailDisabled = false;
            this.isShow = true;
          });
      }
    },

    countDownEmailTimer() {
      this.emailTimerCount = 60;
      let countdown = setInterval(() => {
        this.emailTimerCount -= 1;
      }, 1000);

      setTimeout(() => {
        clearInterval(countdown);
        this.emailDisabled = false;
      }, 60000);
    },

    async validateOtp() {
      if (this.emailCode && this.emailCode.length == 6 && !this.isLoading) {
        this.isLoading = true;
        let params = {
          request_id: this.validationData.request_id,
          email_code: this.emailCode,
        };

        await this.$store
          .dispatch("auth/validateOtp", params)
          .then(() => {
            this.step = 0;
          })
          .catch((error) => {
            this.emailCodeError = this.errorCheck(error);
          })
          .finally(() => {
            this.emailCode = "";
            this.isLoading = false;
          });
      }
    },

    async validateTotp() {
      if (this.code && this.code.length == 6 && !this.isLoading) {
        this.isLoading = true;
        let params = {
          request_id: this.validationData.request_id,
          token: this.code,
        };

        await this.$store
          .dispatch("auth/validateTotp", params)
          .then(() => {
            this.step = 0;
          })
          .catch((error) => {
            this.codeError = this.errorCheck(error);
          })
          .finally(() => {
            this.code = "";
            this.isLoading = false;
          });
      }
    },

    // For passkey, decode & encode base64 string.
    base64url_decode(value) {
      const m = value.length % 4;
      return Uint8Array.from(
        atob(
          value
            .replace(/-/g, "+")
            .replace(/_/g, "/")
            .padEnd(value.length + (m === 0 ? 0 : 4 - m), "=")
        ),
        (c) => c.charCodeAt(0)
      ).buffer;
    },
    base64url_encode(buffer) {
      return btoa(
        Array.from(new Uint8Array(buffer), (b) => String.fromCharCode(b)).join(
          ""
        )
      )
        .replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=+$/, "");
    },

    async beginValidatePasskey() {
      try {
        // console.log(`Begin passkey validation.`);
        const email = this.$store.state.user.email;
        const params = { email };
        const data = await this.$store.dispatch(
          `auth/beginValidatePasskey`,
          params
        );
        const publicKeyOptions = data.options.publicKey;
        // sessionStorage.setItem("passkey_session", data.session_key);

        publicKeyOptions.challenge = this.base64url_decode(
          publicKeyOptions.challenge
        );

        if (publicKeyOptions.allowCredentials) {
          publicKeyOptions.allowCredentials.forEach((item) => {
            item.id = this.base64url_decode(item.id);
          });
        }

        const credential = await navigator.credentials.get({
          publicKey: publicKeyOptions,
        });

        // const sessionKey = sessionStorage.getItem("passkey_session");
        const sessionKey = data.session_key;

        this.attestationResponse = {
          id: credential.id,
          rawId: this.base64url_encode(credential.rawId),
          response: {
            authenticatorData: this.base64url_encode(
              credential.response.authenticatorData
            ),
            clientDataJSON: this.base64url_encode(
              credential.response.clientDataJSON
            ),
            signature: this.base64url_encode(credential.response.signature),
            userHandle: this.base64url_encode(credential.response.userHandle),
          },
          type: credential.type,
          PKAuthParam: {
            email,
            session_key: sessionKey,
            request_id: this.validationData.request_id, // Need to pass this.
          },
        };
        // console.log(this.attestationResponse);

        await this.finishValidatePasskey();
        // sessionStorage.removeItem("passkey_session");
      } catch (error) {
        // sessionStorage.removeItem("passkey_session");
        // console.error(`Error during passkey validation:`, error);
        this.step = 5;
      }
    },

    async finishValidatePasskey() {
      try {
        // console.log(`Finish passkey validation.`);

        await this.$store.dispatch(
          `auth/finishValidatePasskey`,
          this.attestationResponse
        );

        // this.validationStatus.passkey_required = false; // For testing only.
        this.step = 0;
      } catch (error) {
        // console.error(`Error finish passkey validation:`, error);
        this.step = 5;
      }
    },

    async requestValidation() {
      this.isValidating = true;
      let params = {
        request_id: this.validationData.request_id,
      };

      await this.$store
        .dispatch("auth/requestValidation", params)
        .then((data) => {
          // console.log(`# start here`, this.validationStatus, data);
          let falseCount = 0;
          Object.keys(this.validationStatus).forEach((statusKey) => {
            // console.log(`## checking`, statusKey);
            // Need check if current method is enabled, i.e., 2FA & Passkey.
            const checkIsEnabled = Object.hasOwn(
              validationStatusToDataKey,
              statusKey
            );
            // console.log(`need check enabled`, checkIsEnabled);

            // Not in API response, assume not required.
            if (!Object.hasOwn(data, statusKey)) {
              this.validationStatus[statusKey] = false;
              // console.log(`not found in api response, set false`, statusKey);

              // Disable if it is enabled.
              if (checkIsEnabled) {
                const dataKey = validationStatusToDataKey[statusKey];
                if (Object.hasOwn(this, dataKey) && this[dataKey]) {
                  this[dataKey] = false;
                  // console.log(`set to disabled`);
                }
              }
              return;
            }

            this.validationStatus[statusKey] = data[statusKey];
            // console.log(
            //   `set value`,
            //   statusKey,
            //   data[statusKey],
            //   this.validationStatus[statusKey]
            // );
            // Skip check and directly count.
            if (!checkIsEnabled) {
              !data[statusKey] && falseCount++;
              return;
            }

            const dataKey = validationStatusToDataKey[statusKey];
            // console.log(
            //   `checking enable`,
            //   dataKey,
            //   Object.hasOwn(this, dataKey),
            //   this[dataKey],
            //   this
            // );
            // if (Object.hasOwn(this, dataKey) && this[dataKey]) {
            if (
              (dataKey === `isTotpEnabled` && this.isTotpEnabled) ||
              (dataKey === `isPasskeyEnabled` && this.isPasskeyEnabled)
            ) {
              // Enabled, check if it is still required and count.
              !data[statusKey] && falseCount++;
            } else {
              // Not enabled, set to not required.
              this.validationStatus[statusKey] = false;
              // console.log(`not enabled set to not required`);
            }
          });

          // console.log(this.validationStatus, data);
          // this.validationStatus = {...data};
          // const falseCount = Object.values(this.validationStatus).filter(
          //   (value) => !value
          // ).length;
          if (falseCount >= 2) {
            this.$emit("complete");
          }
        });
      this.isValidating = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  @media only screen and (max-width: 768px) {
    margin: 12px 0 !important;
  }
}

.modal-description {
  @media only screen and (max-width: 768px) {
    margin-top: 4px !important;
    margin-bottom: 8px !important;
  }
}

.modal-image {
  @media only screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
}

.verification-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  text-align: center;
  padding: 0 1rem 1rem 1rem;
}

.modal-close-btn {
  place-self: end;
  cursor: pointer;
  position: relative;
}

.verification-btn {
  width: 100%;
  border-radius: 6px;
  text-align: left;
  margin-top: 1rem;
  // cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px;
  border: 1px solid var(--gray50);
  // color: var(--gray);
  color: var(--header-text);

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: 0.75rem;
  }

  .v-icon {
    margin-left: auto;
  }

  img {
    margin-left: auto;
  }

  &.fake-btn {
    border-color: var(--gray30);
    color: var(--gray50);
    padding: 15px 20px;

    @media only screen and (max-width: 768px) {
      padding: 14px 20px;
    }

    .enable-btn {
      border-radius: 4px;
      padding: 6px 8px;
      font-weight: 500;
      font-size: 12px;
      background-color: var(--tertiary25);
      color: var(--secondary-color);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &:not(.fake-btn):hover {
    opacity: 1;

    &:not(:disabled) {
      background-color: var(--hover-color);
    }
  }

  &.selected {
    // color: var(--header-text);
    background: var(--secondary20);
    border: 1px solid var(--secondary-color);
    pointer-events: none;
  }
}

.loading {
  margin-top: 1rem;

  .spinner-border {
    color: var(--primary-color);
  }
}
</style>
