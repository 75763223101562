import { createStore } from "vuex";
import { auth } from "./modules/auth.js";
import { preferences } from "./modules/preferences.js";
import { user } from "./modules/user.js";
import { pixel } from "./modules/pixel.js";
import { autoInvest } from "./modules/GB/autoInvest.js";
import { businesskyc } from "./modules/GB/businesskyc.js";
import { fiat } from "./modules/GB/fiat.js";
import { kyc } from "./modules/GB/kyc.js";
import { orderbook } from "./modules/GB/orderbook.js";
import { otc } from "./modules/GB/otc.js";
import { wallet } from "./modules/GB/wallet.js";
import { referral } from "./modules/GB/referral.js";
import { payment } from "./modules/GB/payment.js";
// MALAYSIA
import { MY_autoInvest } from "./modules/MY/autoInvest.js";
import { MY_businesskyc } from "./modules/MY/businesskyc.js";
import { MY_fiat } from "./modules/MY/fiat.js";
import { MY_kyc } from "./modules/MY/kyc.js";
import { MY_orderbook } from "./modules/MY/orderbook.js";
import { MY_otc } from "./modules/MY/otc.js";
import { MY_wallet } from "./modules/MY/wallet.js";
import { MY_referral } from "./modules/MY/referral.js";

export const store = createStore({
  modules: {
    auth,
    preferences,
    user,
    pixel,
    autoInvest,
    businesskyc,
    fiat,
    kyc,
    orderbook,
    otc,
    wallet,
    referral,
    payment,
    MY_autoInvest,
    MY_businesskyc,
    MY_fiat,
    MY_kyc,
    MY_orderbook,
    MY_otc,
    MY_wallet,
    MY_referral,
  },
});
