<template>
  <v-dialog
    v-model="isShow"
    class="dialog-modal"
    max-width="500px"
    :persistent="persistent"
    @click:outside="hideModal"
  >
    <div class="modal-body-custom" :id="modalId">
      <button @click="$emit('close')" class="modal-close-btn">
        <img src="@/assets/images/modal/close-circle.png" width="22px" />
      </button>
      <img
        :src="require(`@/assets/images/${icon}`)"
        class="modal-img"
        width="60px"
      />
      <div class="modal-title">{{ title }}</div>

      <TextInput
        name="modelValue"
        :label="label"
        v-model="modelValue"
        type="text"
        :placeholder="$t('Profile.enterLabel')"
        :errorMessage="errorMessage"
        @reset="modelValue = ''"
      />

      <button class="main-btn" @click="validateInput">
        <span v-if="!isLoading">{{ buttonText }}</span>
        <span class="spinner-border-sm spinner-border" v-else></span>
      </button>
    </div>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    persistent: { type: Boolean, default: false },
    title: { type: String, default: "" },
    label: { type: String, default: "" },
    modalId: { type: String, default: "" },
    icon: { type: String, default: "" },
    buttonText: {type: String, default: ""},
    isLoading: {type: Boolean, default: false}
  },

  data() {
    return {
      isShow: false,
      modelValue: "",
      errorMessage: ""
    };
  },

  mounted() {
    this.isShow = this.dialog;
  },

  watch: {
    dialog: {
      handler() {
        this.isShow = this.dialog;
        this.modelValue = "";
      },
    },
  },

  methods: {
    hideModal() {
      this.$emit("close");
    },
    validateInput(){
        if(this.modelValue){
            this.errorMessage = ''
            this.$emit('action', this.modelValue);
        } else{
            this.errorMessage = 'This field should not be empty'
        }
    }
  },
};
</script>

<style></style>
