import axios from "axios";
import * as global from "@/globalFunction.js";
import { store } from "@/store/index.js";
import tokenRefreshService from "./token-refresh.js";
import { app } from "@/main";

const methods = global.default.methods;

export default {
  async apiCall(
    method,
    endpoint,
    params = {},
    jwt_key = null,
    crossplatform = false,
    xApiKey = null,
    Signature = null,
    Label = null,
    SessionKey = null,
    Email = null
  ) {
    let url = crossplatform
      ? process.env.VUE_APP_HOST_NAME_MY
      : process.env.VUE_APP_HOST_NAME;
    let key = jwt_key ?? localStorage.getItem("jwt_key");
    let rtoken = localStorage.getItem("rtoken");

    let apiCall = axios.create({
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${key}`,
        RefreshToken: `Bearer ${rtoken}`,
        "X-Api-key": xApiKey,
        Signature: Signature,
        Label: Label,
        "Session-Key": SessionKey,
        Email: Email,
      },
    });

    apiCall.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (
          error.response?.status === 401 &&
          !originalRequest._retry &&
          !originalRequest.url.includes("/refresh-token")
        ) {
          if (tokenRefreshService.isRefreshing) {
            return new Promise((resolve, reject) => {
              tokenRefreshService.failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest.headers["Authorization"] = `Bearer ${token}`;
                originalRequest.headers[
                  "RefreshToken"
                ] = `Bearer ${localStorage.getItem("rtoken")}`;
                return apiCall(originalRequest);
              })
              .catch((err) => Promise.reject(err));
          }

          originalRequest._retry = true;
          tokenRefreshService.isRefreshing = true;

          try {
            await store.dispatch("user/refreshToken");
            const newToken = localStorage.getItem("jwt_key");
            tokenRefreshService.processQueue(null, newToken);
            tokenRefreshService.isRefreshing = false;

            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            originalRequest.headers[
              "RefreshToken"
            ] = `Bearer ${localStorage.getItem("rtoken")}`;
            return apiCall(originalRequest);
          } catch (refreshError) {
            tokenRefreshService.processQueue(refreshError, null);
            tokenRefreshService.isRefreshing = false;

            localStorage.removeItem("jwt_key");
            localStorage.removeItem("rtoken");
            store.dispatch("preferences/setPlatform", 2);
            store.dispatch("auth/initializeAuthState");
            localStorage.removeItem("data");
            localStorage.removeItem("selectedPlat");
            methods.navigateToRoute("SignInView");
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    try {
      let response;
      if (method === "GET") {
        const api = await apiCall.get(url + endpoint, { params });
        response = api.data;
      } else if (method === "POST") {
        const api = await apiCall.post(url + endpoint, params);
        response = api.data;
      } else if (method === "PUT") {
        const api = await apiCall.put(url + endpoint, params);
        response = api.data;
      } else {
        const api = await apiCall.delete(url + endpoint, { data: params });
        response = api.data;
      }

      return response;
    } catch (error) {
      return error.response
        ? error.response.data
        : { message: "Unknown error" };
    }
  },
};
