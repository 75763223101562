import api from "@/assets/api/api_my.js";

const state = {};

const mutations = {};

const actions = {
  async getTokens(context) {
    let response = await api.apiCall("GET", "/otc/api/tokens");

    if (response.status === "success") {
      let crypto = response.data.filter((i) => i.symbol !== "MYR");
      let base = response.data.filter((i) => i.is_base == true);
      // let tokenList = {};
      // base.map((item)=>{
      //     tokenList[item.symbol] = {};
      // })
      // tokenList["USD"] = response.data.filter((i) => i.symbol === "USDT");
      // tokenList["USDT"] = response.data.filter((i) => i.symbol !== "USD");

      return {
        crypto: crypto,
        base: base,
        allTokenList: response.data,
        // tokenList: tokenList
      };
    }
  },

  async getOtcPair(context) {
    let response = await api.apiCall("GET", "/otc/api/pairs");

    if (response.status === "success") {
      let data = response.data;

      let pairs = [];
      let base = [];
      data.map((item) => {
        pairs.push(item);
        base.push(item.base_symbol);
      });

      let quoteList = [];
      for (let i = 0; i < pairs.length; i++) {
        quoteList[pairs[i].quote_symbol] = data.filter((item) => {
          return item.quote_symbol === pairs[i].quote_symbol;
        });
      }

      let baseList = [];
      for (let j = 0; j < base.length; j++) {
        baseList[base[j]] = data.filter((item) => {
          return item.base_symbol === base[j];
        });
      }

      let filteredBase = [];
      let filteredQuote = [];
      let encounteredBases = [];
      let encounteredQuotes = [];
      for (let i = 0; i < pairs.length; i++) {
        if (!encounteredBases.includes(pairs[i].base_symbol)) {
          filteredBase.push(pairs[i]);
          encounteredBases.push(pairs[i].base_symbol);
        }

        if (!encounteredQuotes.includes(pairs[i].quote_symbol)) {
          filteredQuote.push(pairs[i]);
          encounteredQuotes.push(pairs[i].quote_symbol);
        }
      }

      return {
        quoteList: quoteList,
        baseList: baseList,
        quotePairList: filteredQuote,
        basePairList: filteredBase,
        allPairList: data,
      };
    } else {
      console.error(response.message);
    }
  },

  async requestQuote(context, params) {
    let response = await api.apiCall("POST", "/otc/jwt/request-quote", params);

    if (response.status === "success") {
      return response.data;
    } else {
      throw response;
    }
  },

  async acceptQuote({ dispatch }, { params, eventData }) {
    let response = await api.apiCall("POST", "/otc/jwt/accept-quote", params);

    if (response.status !== "success") {
      throw response;
    } else {
      if (eventData.status === "buy") {
        dispatch(
          "pixel/sendPixelEvent",
          { type: 1, symbol: eventData.symbol, amount: eventData.amount },
          { root: true }
        );
      }
    }
  },

  async getHistory(context, params) {
    let response = await api.apiCall("GET", "/otc/jwt/history", params);

    if (response.status === "success") {
      let data = response.data.orders != null ? response.data.orders : [];

      return {
        history: data,
        pages: response.data.pages,
      };
    } else {
      console.error(response.message);
    }
  },
};

export const MY_otc = {
  namespaced: true,
  state,
  mutations,
  actions,
};
